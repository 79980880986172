.mainSpacing {
  padding-top: var(--navSpaceSm);
}

@screen md {
  .mainSpacing {
    padding-top: var(--navSpaceMd);
  }
}

@screen lg {
  .mainSpacing {
    padding-top: var(--navSpaceLg);
  }
}

.progressError {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: rgba(254, 242, 242, 1);
  border-left: 4px solid rgba(220, 38, 38, 1);
}

.progressError > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: rgba(254, 242, 242, 1);
}

.progressError > div > div > p {
  color: rgba(185, 28, 28, 1);
}

.progressError > button {
  color: rgba(185, 28, 28, 1);
}

.progressError > div:last-child {
  background-color: rgba(185, 28, 28, 1);
}

.progressSuccess {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: rgba(236, 253, 245, 1);
  border-left: 4px solid rgba(5, 150, 105, 1);
}

.progressSuccess > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: rgba(236, 253, 245, 1);
}

.progressSuccess > div > div > p {
  color: rgba(4, 120, 87, 1);
}

.progressSuccess > button {
  color: rgba(4, 120, 87, 1);
}

.progressSuccess > div:last-child {
  background-color: rgba(4, 120, 87, 1);
}

.progressInfo {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: rgba(239, 246, 255, 1);
  border-left: 4px solid rgba(37, 99, 235, 1);
}

.progressInfo > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: rgba(239, 246, 255, 1);
}

.progressInfo > div > div > p {
  color: rgba(29, 78, 216, 1);
}

.progressInfo > button {
  color: rgba(29, 78, 216, 1);
}

.progressInfo > div:last-child {
  background-color: rgba(29, 78, 216, 1);
}

/* Toastify__progress-bar Toastify__progress-bar--animated Toastify__progress-bar--info */

.progressWarning {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: rgba(254, 243, 199, 1);
  border-left: 4px solid rgba(252, 211, 77, 1);
}

.progressWarning > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: rgba(254, 243, 199, 1);
}

.progressWarning > div > div > p {
  color: rgba(217, 119, 6, 1);
}

.progressWarning > button {
  color: rgba(217, 119, 6, 1);
}

.progressWarning > div:last-child {
  background-color: rgba(217, 119, 6, 1);
}

.progressDefault {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: rgba(238, 242, 255, 1);
  border-left: 4px solid rgba(99, 102, 241, 1);
}

.progressDefault > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: rgba(238, 242, 255, 1);
}

.progressDefault > div > div > p {
  color: rgba(79, 70, 229, 1);
}

.progressDefault > button {
  color: rgba(79, 70, 229, 1);
}

.progressDefault > div:last-child {
  background-color: rgba(67, 56, 202, 1);
}

.progressDark {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: #313234;
  border-left: 4px solid #ebebf0;
}

.progressDark > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: #313234;
}

.progressDark > div > div > p {
  color: #f5f5f8;
}

.progressDark > button {
  color: #f5f5f8;
}

.progressDark > div:last-child {
  background-color: #dbdbe2;
}
