.childWrapper {
  position: absolute;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow-y: auto;
}

.parent {
  will-change: transform;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.parent::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.viewportHeight {
  height: calc(100% - 45px);
}
